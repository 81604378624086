import * as React from "react"
import { Link } from "gatsby"
import { useState } from "react"
import { PlayOutline, StarOutline, InformationCircleOutline } from 'react-ionicons'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import EndCheckout from '../../components/EndCheckout.js'
import Footer from '../../components/Footer.js'
import Logo from '../../components/Logo.js'
import { CheckoutContext } from "../../context/CheckoutContext"
import ReactPlayer from 'react-player'



const Stem = (props) => {
    return <article class="box">

        <div className="columns is-mobile content">
            <div className="column is-narrow">
                <p className="image is-96x96 stem-image">
                    <img src={props.image} alt={props.title} class="is-rounded" />
                </p>
            </div>
            <div className="column">
                <p className="title is-5">{props.title}</p>
                <p class="subtitle is-6">{props.native}</p>
                <p>{props.description}</p>
            </div>
        </div>




        {/* <hr /><div className="content">



                    <p class="title is-6">Aprūpe</p>
                    <p>{props.care}</p>

        </div> */}



    </article>
}

const Video = ({ url, captions }) => {
    return (<ReactPlayer
        url={url}
        width='100%'
        height='100%'
        // muted={true}
        config={{
            file: {
                attributes: {
                    controls: true,
                },
            },
            tracks: [
                { kind: 'captions', src: captions, label: "Instrukcijas", srcLang: 'lv', default: true }
            ]
        }}

    />)
}


// markup
const Hi = () => {

    const flowers = [
        {
            title: "Āzijas gundega jeb Ranunculus",
            image: "https://i.imgur.com/0hHCxKnb.jpg",
            native: "Āzija",
            description: "Šoreiz izvēlējāmies koši dzeltenās krāsas Ranunculus Cloony Mix šķirnes ziedus. Vāzē tās var dzīvot līdz pat 10-12 dienām. Tuvākajās dienās tās vēl atvērsies un paliks 2x lielākas kā saņemšanas brīdī",
        },

        {
            title: "Pildītās rozes",
            image: "https://i.imgur.com/ymrWEQib.jpg",
            native: "Āzija",
            description: "Šķirne Pink Piano Freiland. Vai zināji, ka pasaulē lielākā rozes zieda diametra rekords ir 84cm? Šī šķirne gan nesasniegs tādu izmēru.",
        },
        {
            title: "Lizantes",
            image: "https://i.imgur.com/YzJZNNYb.jpg",
            native: "Dienvidamerika",
            description: "Šoreiz izvēlējāmies baltas krāsas ar lokainām maliņām, kas tāpēc izceļas ar savu faktūru un vizuāli ļoti atsvaidzina ziedu kompozīciju. Šie ziedi vāzē var ilgt līdz pat 14 dienām.",
        },
        {
            title: "Tulpes",
            image: "https://i.imgur.com/wHFFON6b.jpg",
            native: "Āzija",
            description: "Tulpes ir pavasara vēstneses un karalienes. Leģendāras ar savu kādreiz augsto cenu, tulpju mānijas laikā, kad tās maksāja vairāk kā nekustamais īpašums. Ja tās pareizi kopj, nomainot vāzē ūdeni katru dienu, tās var ziedēt pat līdz 7-9 dienām."
        },
        
        {
            title: "Mazās krizantēmas",
            image: "https://i.imgur.com/Cle2HIMb.jpgpg",
            native: "Ķīna",
            description: 'Audzētas jau 15. gadsimtā pirms mūsu ēras. To nosaukums cēlies no grieķu valodas "zelta puķe". Šie ziedi vāzē var ilgt 7 līdz 14 dienas.',
        },
        {
            title: "Mazās krizantēmas (lime)",
            image: "https://i.imgur.com/4NYsRHvb.jpg",
            native: "Ķīna",
            description: 'Audzētas jau 15. gadsimtā pirms mūsu ēras. To nosaukums cēlies no grieķu valodas "zelta puķe". Šie ziedi vāzē var ilgt 7 līdz 14 dienas.',
        },
        {
            title: "Ruscus lapas",
            image: "https://i.imgur.com/tl1Pabub.jpg",
            native: "Eiropa un Tuvie Austrumi",
            description: "Ar biezām, tumši zaļām lapām šis augs zināms arī kā slota. Tie dabā ir mazi krūmi, kuri ražo sarkanas ogas.",
        },
        {
            title: "Pistāciju lapas",
            image: "https://i.imgur.com/3oYQgBmb.jpg",
            native: "Centrālāzija un Tuvie Austrumi",
            description: "Pistāciju koku ģints ne tikai dod gardos riekstus, bet arī citus produktus, tostarp skaistus dekoratīvus zarus, kuru lapas lieliski papildina ziedu kompozīcijas.",
        }
    ]
    return (
        <main class="">


            <Helmet title="Instrukcija un atsauksmes" defer={false} />

            <section class="section">

                <div class="columns is-mobile">
                    <div class="column pt-0">
                        <Logo />
                    </div>
                    <div class="column is-one-third pt-0">
                        <EndCheckout />
                    </div>
                </div>
                <hr />


                <div className="container content">


                    <h1 class="title is-3 has-text-centered">👋 Tavi ziedi ir klāt!</h1>


                    {/* <p className="title is-3">Ko darīt tālāk?</p> */}

                    <div class="columns  has-text-centered">
                        <div class="column">
                            <PlayOutline height="22px" width="22px" />
                            <p>Noskaties īso instrukciju video</p>
                        </div>
                        <div class="column">
                            <InformationCircleOutline height="22px" width="22px" />
                            <p>Iepazīsties ar padomiem, lai paildzinātu prieku par ziediem</p>
                        </div>
                        <div class="column">
                            <StarOutline height="22px" width="22px" />
                            <p>Novērtē saņemtos ziedus un padalies ar draugiem</p>
                            <p>
                                <a href="https://d6f6g7elvfj.typeform.com/to/q5g4j6lP" target="_blank" class="button is-outlined">Aizpildīt novērtējumu</a>

                            </p>
                        </div>
                    </div>

                </div>
            </section>

            <section className="section" style={{ backgroundColor: '#4a4a4a' }}>
                <div className="container content">

                    <h2 class="title is-3" style={{ color: '#eee' }}>Instrukcija</h2>

                    <Video
                        url="https://vz-da54803c-c33.b-cdn.net/4600f769-075a-4de5-a6c4-3e5d1d43f032/playlist.m3u8"
                        captions="/titles/005.vtt"
                    />
                </div>
            </section>


            <section className="section">
                <div className="container content">

                    <div className="columns">
                        <div className="column">


                            <h2 class="title is-3">Pavasari, mēs tevi gaidām!</h2>

                            <p>Pavasari vēl ārā neredzam, bet ziedos gan. Šonedēļ turpinām draudzēties ar dažādām krāsām, dzelteno, perlamutra violeto, balto,  maigi rozā un koši zaļo.</p>
                                
                            <p>Kompozīcijā ziedus iesakām izkārtot proporcionāli, piemēram, vāzē 3 ziedus izkārtot vienādos attālumos vienu no otra. Un tā visus ziedus, tas radīs harmonisku un viendabīgu ziedu sadalījumu un izkārtojumu vāzē. Vāze, vislabāk derēs vidēji liela vāze ar vidēji platu kaklu. Apakšējais - zaļās lapas.</p>

                            <p>Ieteikumi:</p>
                            <p>Dzeltenie Ranunculus; maigi rozā Rozes; baltās Lizantes un perlamutra violetās tulpes atvērsies, tāpēc izvēlies piemērotu vāzi, lai ziediem būtu vieta kompozīcijā vēl augt un atvērties</p>

                            <p>Daži no ziediem, kā Ranunculus var būt trauslāki par, piemēram, Krizantēmām, tāpēc vari izmantot tās vai pistāciju zarus, kā atbalsta punktus</p>

                            <p>Ķeramies pie darba!</p>

                            <ol>
                                <li>Vāzē lej ūdeni istabas temperatūrā </li>
                                <li>Pievieno ziedu barību, 1 paciņa uz 0,5l ūdens, samaisi</li>
                                <li>Obligāti, visiem ziedu stublājiem nogriez 2cm no apakšas, lai tie spētu uzsūkt ūdeni un ilgāk paliktu svaigi</li>
                                <li>Sākam ar zaļajiem pistāciju lapas zariņiem, ko izkārtojam pa vāzes perimetru</li>
                                <li>Vienādos attālumos pa perimetru izkārtojam gan zaļās, gan baltās krizantēmas</li>
                                <li>Tālāk kārtojam 4 tulpes, ja iedomājies pulksteni, tad izkārto tās plkst 12:00; 3:00; 6:00; 9:00</li>
                                <li>Baltās Lizantes izkārtojam tuvāk centram, arī vienādos attālumos vienu no otras</li>
                                <li>Maigi rozā rozes, liec netālu no tulpēm, jo krāsu saderība ir fantastiski skaista</li>
                                <li>Visbeidzot dzeltenās saulītes Ranunculus izkārtojam, kur atļauj vieta, bet ieteicams vienādos attālumos, lai kompozīcija veidojas harmoniska krāsu ziņā, jo acīm ļoti patīk “atkārtošanās”.</li>
                                {/* <li>Pirmais triks, ziedu stublājiem jābūt vienā garumā, lai spētu izveidot apaļu kompozīciju, to garums ir atkarīgs no Tavas vāzes augstuma</li> */}
                                {/* <li>Sāc ar zaļi baltajiem zariņiem (Pittosporum), kur tos pavisam vienkārši izkārto vāzē - šie zariņi ir kā skelets kompozīcijai, kas dos apjomu un noturēs formu </li> */}
                                {/* <li>Tālāk liec stap zariem Viburnum Roseum jeb sniega bumbas. Triks ir izmantot zaļi baltos zariņus, kā atbalstus, lai uz tiem uzliktu smagos ziedus, kā arī tie ļauj simetriski izkārtot ziedu bumbas kompozīcijā. Grūtākais ir padarīts</li> */}
                                {/* <li>Izkārto vāzē pildītās, lielās Rozes, Krūmrozes un smaržīgās Gerberas </li> */}
                                {/* <li>Visbeidzot kā pēdējās liec vāzē Anemones, tie ir ļoti trausli ziedi, tāpēc tās tiks piegādās vēl pumpuros, bet atrodoties istabas temperatūrā tās pavisam neilgā laikā atvērsies</li> */}
                            </ol>

                            <p>Kā patīk šīs nedēļas kompozīcija? Atsūti foto Messenger čatā, loga stūrī!</p>

                            <h3 class="title is-4">Par ziedu barību</h3>

                            <p>Ziedu barībai ir svarīgi ievērot norādīto barības-ūdens attiecību, lai tā nav par stipru vai vāju. Ja tā būs par koncentrētu, tad ziedi var apdegt, ja  koncentrācija par vāju, tad ziediem pietrūkt vajadzīgās barības vielas un tie ātrāk novecos. Mūsu līdzi dotā barības vielas paciņa, tiek šķīdināta 0,5l ūdens</p>


                        </div>
                        <div className="column">

                            <img src="https://i.imgur.com/vGqOo0Uh.jpg" alt="Nedēļas pušķis"/>

                            <h2 class="title is-34">Aprūpe</h2>

                            <p>Īpaši labi ziedi stāvēs, ja ūdeni tiem mainīsi reizi 2 dienās. Kā to pareizi darīt? </p>

                            <ol>
                                <li>Noskalo ziedu kātus</li>
                                <li>Izmazgā vāzi</li>
                                <li>Iepildi istabas temperatūras ūdeni, tajā izšķīdinot ziedu barību</li>
                                <li>Ziediem nogriez 3cm no stublāju apakšas, ar asām šķērēm griežot slīpi, 45° lenķī</li>
                            </ol>

                        </div>
                    </div>




                </div>
            </section>




            <section className="section" style={{ backgroundColor: '#F0E2D7' }}>
                <div className="container content">

                    <p className="title is-3">Kādus ziedus saņēmi?</p>

                    <div className="columns is-multiline">
                        {flowers.map(f => {
                            return (
                                <div className="column is-one-third">
                                    <Stem
                                        title={f.title}
                                        latin={f.latin}
                                        image={f.image}
                                        native={f.native}
                                        care={f.care}
                                        description={f.description}
                                    />

                                </div>
                            )
                        })}


                    </div>


                </div>
            </section>


            <section className="section content">
                <div className="container">

                    <div className="columns">
                        <div className="column">

                            <h2 class="title is-3">Novērtējums</h2>

                            <p>Mēs priecāsimies, ja atstāsi godīgu novērtējumu par Heyday pakalpojumu.</p>
                            <p>Tas aizņems tikai 2 minūtes.</p>

                            <a href="https://d6f6g7elvfj.typeform.com/to/q5g4j6lP" target="_blank" class="button is-primary">Aizpildīt anketu</a>


                        </div>
                        <div className="column">

                            <h2 class="title is-3">Pasaki par mums saviem draugiem</h2>

                            <p>Ja Tev saņemtie ziedi patika, padalies ar Heyday saviem draugiem. Jo vairāk cilvēki pievienojas mūsu lokam, jo plašāk varēsim sniegt ziedu prieku.</p>

                            <p>
                                <a class="button is-success" href="whatsapp://send?text=Heyday ziedu abonements https://heyday.lv?utm_source%3Dshare%26utm_medium%3Dwhatsapp%26utm_campaign%3Dfromhi" data-action="share/whatsapp/share">Pārsūtīt saiti Whatsapp</a>
                            </p>


                        </div>
                    </div>





                </div>


            </section>

            <Footer />



        </main>
    )
}

export default Hi
